import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

const Newsletter = () => {
  const { newsletter } = useStaticQuery(graphql`
    query {
      newsletter: sanityNewsletter {
        heading
        description
        optInLanguage
        btnText
        antispamNotice
      }
    }
  `)

  return (
    <div className="bg-green-700">
      <div className="max-w-screen-lg mx-auto lg:grid lg:grid-cols-2 py-10 px-4 sm:px-6 lg:py-12 lg:px-8 lg:flex lg:items-center">
        <div className="">
          <h2
            className="text-2xl leading-8 font-extrabold tracking-tight text-white sm:text-3xl sm:leading-10"
            id="newsletter-headline"
          >
            {newsletter.heading}
          </h2>
          <p className="mt-3 max-w-3xl text-base leading-6 text-gray-300">
            {newsletter.description}
          </p>
        </div>

        <div className="mt-8 lg:mt-0 lg:ml-8">
          <form
            method="POST"
            action="https://ashbrookra.activehosted.com/proc.php"
            id="_form_3_"
            noValidate
            aria-labelledby="newsletter-headline"
            className="fle flex-col space-y-2 items-center"
          >
            <input type="hidden" name="u" value="3" />
            <input type="hidden" name="f" value="3" />
            <input type="hidden" name="s" />
            <input type="hidden" name="c" value="0" />
            <input type="hidden" name="m" value="0" />
            <input type="hidden" name="act" value="sub" />
            <input type="hidden" name="v" value="2" />
            <input
              type="text"
              name="field[4]"
              placeholder="State"
              className="hidden"
            />

            <input
              required
              type="email"
              name="email"
              aria-label="Email address"
              placeholder="Email address"
              className="appearance-none w-full px-3 py-2 border border-transparent text-base leading-6 rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 transition duration-150 ease-in-out"
            />

            <input
              type="tel"
              id="phone"
              name="phone"
              minLength={10}
              maxLength={10}
              pattern="[0-9]{10}"
              aria-label="Phone number"
              placeholder="Phone number (optional)"
              className="appearance-none w-full px-3 py-2 border border-transparent text-base leading-6 rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 transition duration-150 ease-in-out"
            />

            <div className="mt-3 rounded-md shadow sm:mt-0">
              <button className="w-full flex items-center justify-center px-3 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-orange-500 hover:bg-orange-400 focus:outline-none focus:bg-orange-400 transition duration-150 ease-in-out">
                {newsletter.btnText}
              </button>
            </div>
          </form>
          <p className="mt-3 leading-4 text-gray-300" style={{ fontSize: 10 }}>
            We promise to never send you spam or flood your inbox. I agree to be
            contacted by Ashbrook Land Company via call, email, and/or text. (To
            opt out, you can reply 'stop' at any time or click the unsubscribe
            link in the emails. Message and data rates may apply, message
            frequency varies.){" "}
            <Link to="/privacy-policy" className="underline">
              Privacy Policy
            </Link>{" "}
            &{" "}
            <Link to="/terms" className="underline">
              Terms
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Newsletter
