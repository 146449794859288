import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

const Hero = ({ hero }) => {  
  const { heading, subheading, btnText, secondaryBtnText, backgroundImage } = hero; 

  return (
    <div className="relative h-112 md:h-136 lg:h-152 flex flex-col justify-center items-center lg:block overflow-hidden">
      <div id="hero-overlay" className="absolute block inset-y-0 h-full w-full">        
        <Img className="h-full w-full object-top overflow-hidden" fluid={backgroundImage.asset.fluid} alt="Hero Background Image New Mexico Countryside" />
      </div>
      <div className="absolute inset-y-0 h-full w-full opacity-35">
        <div className="z-10 h-full w-full bg-gradient-to-tr from-green-600 to-orange-500 overflow-hidden"></div>
      </div>      

      <div className="relative pt-6 pb-6 sm:pb-10 mx-auto max-w-screen-xl px-4 sm:pt-12 sm:px-6 md:pt-36 text-center">
        <h1 className="max-w-4xl mx-auto text-3xl tracking-tight leading-10 font-extrabold sm:text-5xl sm:leading-none md:text-6xl text-gray-50">
          { heading }
        </h1>
        <p className="mt-3 max-w-md mx-auto text-base text-gray-100 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
          { subheading }
        </p>
        <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
          <div className="rounded-md shadow">
            <Link to="/properties" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
              { btnText }
            </Link>
          </div>
          <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
            <Link to="/about" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-green-600 bg-white hover:text-green-500 focus:outline-none focus:border-green-300 focus:shadow-outline-green transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
              { secondaryBtnText }
            </Link>
          </div>
        </div>        
      </div>  
    </div>
  )
}

export default Hero
