import React from "react"
import { Link } from "gatsby"
import { showSoldPropertiesLast, keepFeaturedProperties } from "../../../utils/properties"

import PropertyCard from "../../properties/components/property-card"

const FeaturedProperties = ({ properties, sectionData }) => {
  return (
    <section className="relative bg-gray-50 pt-16 pb-20 px-0 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3"></div>
      </div>
      <div className="relative container">
        <div className="text-center">
          <h2 className="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
            {sectionData.heading}
          </h2>
          <p className="mt-3 max-w-2xl mx-auto text-xl leading-7 text-gray-500 sm:mt-4">
            {sectionData.subheading}            
          </p>
        </div>

        <div className="mt-12 px-4 lg:px-0 grid gap-8 max-w-lg mx-auto sm:grid-cols-2 sm:max-w-4xl lg:grid-cols-3 lg:max-w-none">
          {showSoldPropertiesLast(keepFeaturedProperties((properties)))
            .slice(0, 3).map((property) => (
              <PropertyCard key={property.id} property={property} />)
            )
          }
        </div>

        <div className="mt-12 flex justify-center">
          <div className="inline-flex rounded-md shadow">
            <Link to="/properties" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-bold rounded-md text-white bg-green-600 shadow-md hover:shadow-lg hover:bg-green-700 focus:outline-none focus:shadow-outline transition duration-200 ease-in-out">
              See All Properties
              <svg className="-mr-1 ml-3 h-5 w-5 text-white" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd" d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5zM5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" clipRule="evenodd"/>
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeaturedProperties