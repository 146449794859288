import React from "react"
import Img from "gatsby-image"

import { Link } from "gatsby"
import formatToUsd from "../../../utils/currency"
import { toPlainText } from "../../../utils/text"

const PropertyCard = ({ property }) => {
  const {
    title,
    slug,
    sold,
    acres,
    images,
    _rawDescription,
    property_details,
  } = property
  const thumbnail = images[0]
  const description = toPlainText(_rawDescription)

  const renderPriceLabel = () => {
    if (property_details.monthly_payment) {
      /* Display owner financing, if available */
      return (
        <span className="z-20 absolute left-0 top-0 rounded-br-md overflow-hidden px-3 py-1 bg-green-800 text-sm text-white">
          {formatToUsd(property_details.monthly_payment)} / mo
        </span>
      )
    } else if (property_details.cash_price) {
      /* Display cash price, if no owner financing */
      return (
        <span className="z-20 absolute left-0 top-0 rounded-br-md overflow-hidden px-3 py-1 bg-green-800 text-sm text-white">
          {formatToUsd(property_details.cash_price)}
        </span>
      )
    } else {
      console.error("Error! Encountered a property with no price.")
      return null
    }
  }

  console.log(property_details)

  return (
    <article className="bg-white flex flex-col rounded-md shadow-lg hover:shadow-2xl transition-all duration-200 ease-in-out transform hover:-translate-y-2 overflow-hidden">
      <Link to={`/properties/${slug.current}`}>
        <div className="flex-shrink-0 relative bg-white">
          {sold && (
            <div className="absolute z-10 w-full h-full flex flex-col justify-center items-center bg-green-600 opacity-75">
              <h4 className="text-3xl tracking-wide font-bold text-white">
                SOLD!
              </h4>
            </div>
          )}

          <div className="overflow-hidden h-48 w-full">
            <Img
              className="h-full w-full object-cover"
              fluid={thumbnail.asset.fluid}
              alt="Property Thumbnail"
            />
          </div>

          <span className="z-20 absolute right-0 bottom-0 rounded-tl-md overflow-hidden px-3.5 py-1.5 bg-secondary text-sm text-white uppercase">
            {acres} acres
          </span>

          {renderPriceLabel()}
        </div>
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
            <p className="text-sm leading-5 font-medium text-green-600">
              {property_details.county} County | {property_details.state}
            </p>
            <div className="block">
              <h3 className="mt-2 h-12 text-xl leading-6 font-semibold text-gray-900">
                {title.length >= 48 ? title.substring(0, 48) + "..." : title}
              </h3>
              <p className="hidden sm:block mt-4 text-base leading-6 text-gray-500">
                {description.length >= 120
                  ? description.substring(0, 120) + "..."
                  : description}
              </p>
            </div>
          </div>

          <div className="mt-7 sm:mt-5 w-full flex items-center justify-between">
            <div className="w-full">
              <button className="inline-flex w-full items-center justify-center px-5 py-2 border border-transparent text-base leading-6 font-bold rounded-md bg-green-600 sm:bg-transparent text-white sm:text-green-600 border-4 border-green-600 hover:text-white hover:border-transparent hover:bg-green-600 shadow-sm focus:outline-none focus:shadow-outline transition duration-200 ease-in-out">
                View more
              </button>
            </div>
          </div>
        </div>
      </Link>
    </article>
  )
}

export default PropertyCard
