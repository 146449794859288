import React from "react"
import { graphql } from "gatsby"

import Newsletter from "../../components/newsletter"
import Layout from "../../components/layout"
import Hero from "./components/hero"
import SEO from "../../components/seo"

import FeaturedProperties from "./components/featured-properties"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title={data.homePage.nodes[0].seo_title}
        description={data.homePage.nodes[0].seo_description}
      />

      <Hero hero={data.homePage.nodes[0].hero} />
      <Newsletter />
      <FeaturedProperties
        sectionData={data.homePage.nodes[0].featuredProperties}
        properties={data.properties.nodes}
      />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexPageQuery {
    homePage: allSanityHomePage {
      nodes {
        seo_title
        seo_description
        hero {
          heading
          subheading
          btnText
          secondaryBtnText
          backgroundImage {
            asset {
              fluid(maxWidth: 1400) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        featuredProperties {
          heading
          subheading
        }
      }
    }
    properties: allSanityProperty(filter: { hidden: { eq: false } }) {
      nodes {
        id
        slug {
          current
        }
        sold
        featured
        title
        _rawDescription
        acres
        property_details {
          state
          county
          cash_price
          monthly_payment
        }
        images {
          asset {
            fluid(maxWidth: 450) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`
